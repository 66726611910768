@font-face {
  font-family: 'AkkuratRegular';
  src: url('./fonts/AkkuratRegular.woff');
}

@font-face {
  font-family: 'PTSerif';
  src: url('./fonts/AkkuratRegular.woff');
}

*, *:after, *:before {
  box-sizing: border-box;
}

html {
  font-size: 100%;
} /*16px*/

html, body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  height: 100%;
}

body {
  font-family: AkkuratRegular, sans-serif;
  line-height: 1.8;
  display: flex;
  flex-direction: column;
  background: #e1f5fe;
}

#root {
  flex: 1;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-direction: column;
}

h1, h2, h3 {
  font-family: "PT Serif", Times New Roman, serif;
}

h3 {
  margin: 0;
}
